






import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
  props: {
    pattern: {
      type: String,
      default: null,
    }
  }
})

export default class Feedback extends Vue {

  private comments = []
  private isLoading = true
  private isFullPage = true
  private ids = [] as any

  private passPatternData(name: string) {
    this.$emit('emitPatternData', {patternName: name, webpage: 'Feedback'})
    this.$router.push({name:'Patterns', params: {feedbackMode: 'true', displayMessage: `${this.$store.state.firstFeedback}`}})
    this.$store.commit('feedbackGiven', true)
  }

  private async getPatternWithLowestCommentsCount() {
    // Ursprüngliche Intention: "Zufälliges Pattern mit niedrigsten Kommentaranzahl für Feedback suchen"
    // -> Für Feedbackrunde im März eher "unbrauchbar"
    // TODO: Falls wieder gebraucht unten stehenden Code löschen und Kommentierung der Zeile 36-52 rausnehmen und code nutzen!
    /**await Vue.axios.get(`${this.$store.state.root}/api/comments`).then(resp => {
      this.comments = resp.data
      let commentsInPattern = this.comments.map(a => a['pattern'])

      Vue.axios.get(`${this.$store.state.root}/api/patterns/`).then(resp => {
        let filteredPatterns = resp.data.filter((c: any) => (c.stage !== 4))
        let commentCounts = {} as any
        filteredPatterns.forEach((pattern: { patternId: number }) => { commentCounts[pattern.patternId] = 0 })
        commentsInPattern.forEach(function (x) { commentCounts[x] = commentCounts[x] + 1 })

        let patternWithLowestCommentCount = Object.keys(commentCounts).reduce((a, b) => commentCounts[a] < commentCounts[b] ? a : b)

        Vue.axios.get(`${this.$store.state.root}/api/patterns/${patternWithLowestCommentCount}`).then(resp => {
          this.passPatternData(resp.data.name)
        })
      })
    })**/

    // TODO: Code löschen wenn nicht mehr gebraucht! (Nach März)
    // Zufällig ID auswählen zwischen 2-5, 109-131 und alles größer 175

    Vue.axios.get(`${this.$store.state.root}/api/patterns/`).then(resp => {
      let patterns = resp.data
      patterns = patterns.filter((pattern: any) => (2 <= pattern.patternId && pattern.patternId <= 5) || (109 <= pattern.patternId && pattern.patternId <= 131) || (175 < pattern.patternId)).map((x: any) => x.patternId)
      this.ids = patterns
      // Zufällig id
      let id = 0
      let min = 0;
      let max = this.ids.length;
      let x = Math.floor(Math.random() * (max - min)) + min;
      id = this.ids[x]
      // Übergeben
      Vue.axios.get(`${this.$store.state.root}/api/patterns/${id}`).then(resp => {
        this.$store.commit('feedback', id)
        this.passPatternData(resp.data.name)
      })
    })
  }
  
  created() {
    if (this.$props.pattern != null){
      this.passPatternData(this.$props.pattern)
    }
    else {
      this.getPatternWithLowestCommentsCount()
    }
  }
}

